import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import PtzPage from "~/components/Simulators/PtzPage";

export default function PtzRoute() {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Un terrain, une maison ou un immeuble à vendre ? Recevez une estimation d'achat de votre bien sous 72h, en direct promoteur et au meilleur prix.",
    },
  };

  return (
    <>
      <Title>Simulation PTZ : le calcul de votre prêt à taux zéro</Title>
      <MetaTags tags={[metaDesc]} />
      <PtzPage />
    </>
  );
}
