import { clientOnly } from "@solidjs/start";
import { utmStoreGet } from "~/lib/utm_store";
import CommonFirstScreenImage from "~/components/shared/CommonFirstScreenImage";

import "~/components/shared/CommonPageStyle.css";
import "~/components/shared/CommonIntroduction.css";
import "~/components/shared/ContentSEOText.css";
import "./SimulatorPage.css";
import { useFormStorageContext } from "~/contexts/FormStorageProvider";

const SimulatorPtz = clientOnly(() => import("../Forms/SimulatorPtz"));

export default function PtzPage() {
  const [formState] = useFormStorageContext();
  const hiddenFields = () => {
    return {
      base_email: utmStoreGet("utm_source"),
      campaign: utmStoreGet("utm_campaign"),
      source: utmStoreGet("utm_source"),
      medium: utmStoreGet("utm_medium"),
      term: utmStoreGet("utm_term"),
      content: utmStoreGet("utm_content"),
    };
  };

  return (
    <>
      <article class="common-page-style simulator-page simulator-ptz">
        <CommonFirstScreenImage image="/images/simulator-ptz.jpg" alt="" />

        <div class="outer-content-area" id="top">
          <section class="content-area">
            <div class="inner">
              <SimulatorPtz
                hiddenFields={{ form_id: "simulator-ptz", ...hiddenFields() }}
                defaultValues={{
                  CoutHorsFN: formState.values?.PtzCoutHorsFN,
                  RevFiscRef: formState.values?.PtzRevFiscRef,
                }}
              />
            </div>
          </section>
        </div>

        <PtzSeoText />
      </article>
    </>
  );
}

function PtzSeoText() {
  return (
    <>
      <section class="content-seo-text" data-test="seo-block">
        <div class="block">
          <h2>Simulation PTZ, êtes-vous éligible ?</h2>
          <p>
            L'achat d'une résidence principale est le rêve de milliers de
            familles. Malheureusement, tout projet immobilier nécessite un
            budget et économiser pour investir dans l'immobilier peut prendre
            plusieurs années. La bonne nouvelle, c'est qu'en France, le
            gouvernement propose une aide aux familles possédant des revenus
            moyens et/ou modestes par le biais du PTZ.
          </p>
          <p>
            Découvrez en moins de 2 minutes si vous pouvez bénéficier du prêt à
            taux zéro pour financer votre résidence principale. Pour obtenir les
            résultats de votre simulation et être accompagné(e) et renseigné(e)
            sur celle-ci par un conseiller immobilier par téléphone et/ou email,
            merci de nous préciser les informations suivantes.
          </p>
        </div>
        <div class="block">
          <h2>Réglementation du prêt à taux zéro en 2024</h2>
          <h3>Conditions générales</h3>
          <p>
            Le prêt à taux zéro est réservé à l'acquisition de votre résidence
            principale. Il est exclu si vous êtes déjà propriétaire de celle-ci
            ou si vous l'avez été dans les deux ans précédant l'émission de
            l'offre de prêt.
          </p>
          <p>Les acquisitions éligibles sont :</p>
          <ul>
            <li>l'achat ou la construction d'un logement neuf ;</li>
            <li>
              l'achat d'un logement ancien suivi de travaux de remise à neuf ;
            </li>
            <li>
              l'achat d'un logement ancien suivi de travaux représentant au
              moins 25% du coût total ;
            </li>
            <li>
              l'achat d'un local non destiné à l'habitation suivi de travaux de
              transformation en logement ;
            </li>
            <li>
              l'achat d'un logement neuf par son locataire-accédant en PSLA ;
            </li>
            <li>le rachat d'un logement HLM par son locataire.</li>
          </ul>
          <p>
            Le prêt peut financer le prix du logement et, en cas d'achat
            d'ancien éligible, le coût des travaux. Il ne peut pas financer les
            frais de notaire, de garantie ou bancaires. Le prêt est exclu si
            votre revenu fiscal de référence excède un certain plafond de
            revenu. Ce plafond dépend de la localisation du bien. Le prêt est
            également exclu si le coût d'acquisition hors frais excède neuf fois
            ce plafond de revenu.
          </p>
          <h3>Conditions relatives au montant et à la durée du prêt</h3>
          <p>
            Le montant du prêt est plafonné. Ce plafond dépend de la
            localisation du bien (zones A, B1, B2 ou C), du caractère neuf ou
            ancien du logement, et du nombre de personnes dans le ménage. Le
            montant du prêt est également limité à 50% de la dépense finançable.
            Ce pourcentage est ramené à 10% s'il s'agit d'un rachat de logement
            HLM sans travaux ou avec travaux inférieurs à 25% de la dépense
            finançable. Le montant du prêt ne doit pas excéder le montant cumulé
            des autres prêts d'une durée supérieure ou égale à 24 mois
            concourant au financement de l'opération, soit 50% du montant total
            des prêts.
          </p>
          <p>
            Le remboursement du prêt comprend une période de différé total (5,
            10 ou 15 ans) suivie d'une période d'amortissement (15, 12 ou 10
            ans). La durée du différé dépend du niveau du revenu fiscal, réparti
            selon 3 tranches. Le montant plafond de chaque tranche est fonction
            de la localisation du bien (A, B1, B2, C) et du nombre de personnes
            dans le ménage. Pour déterminer la tranche applicable, le revenu
            fiscal est supposé être au moins égal à un neuvième du coût de
            l'opération. La durée du différé est également limitée à la durée
            des autres prêts.
          </p>
          <h3>Les emprunteurs concernés par le PTZ</h3>
          <p>
            Le PTZ n'est pas forcément adapté au grand public. Il est attribué
            sous conditions de ressources. Autres clauses s’avèrent également
            d’une grande importance.
          </p>
          <p>
            <strong>Débiteur salarié</strong>
          </p>
          <p>
            Vous devez avoir des sources de revenus stables pour rembourser vos
            dettes tous les mois. La banque doit également être informé sur vos
            autres prêts en cours.
          </p>
          <p>
            <strong>Emprunteur non propriétaire d’un immobilier</strong>
          </p>
          <p>
            Tous les particuliers désireux de bénéficier de ce type de prêt
            doivent répondre à certains critères. Vous devez être concerné par
            aucun acte de propriété au cours des 2 dernières années précédant la
            demande du prêt. Dans le cas où vous venez de céder votre bien
            immobilier, vous devez donc choisir un autre type de prêt immobilier
            et payer évidement le taux d’intérêt.
          </p>
          <p>Par contre, cette exigence peut être ignorée lorsque :</p>
          <ul>
            <li>
              le débiteur est l'usufruitier de sa propre résidence principale ;
            </li>
            <li>s'il dispose des justificatifs d'incapacité d'invalidité ;</li>
            <li>
              s'il habite dans une maison inhabitable suite de catastrophes
              naturelles.
            </li>
          </ul>
          <h3>Les Zones A, B1, B2 et C</h3>
          <ul>
            <li>
              Zone A : principalement les agglomérations de la région
              d’Île-de-France, la Côte d’Azur, Montpellier, Lyon, Marseille,
              Toulon, Lille, Toulouse, Bordeaux, Strasbourg, Annecy ainsi que le
              Genevois français.
            </li>
            <li>
              Zone B1 : agglomérations de plus de 250.000 habitants, grande
              couronne parisienne, pourtour de la Côte d'Azur, quelques
              agglomérations chères, départements d'Outre-Mer, agglomérations
              littorales corses, Iles.
            </li>
            <li>
              Zone B2 : autres agglomérations de plus de 50.000 habitants,
              communes chères en zones littorales ou frontalières, pourtour de
              l'Ile-de-France, reste de la Corse.
            </li>
            <li>
              Zone C : communes de province non classées en zone A, B1 ou B2.
            </li>
          </ul>
        </div>
        <div class="block">
          <h2>Le calcul du montant du PTZ</h2>
          <p>
            Tout particulier peut faire une simulation PTZ pour obtenir le
            montant de son prêt éligible au prêt à taux zéro. Il est même
            recommandé de faire cette simulation avant de souscrire votre prêt
            immobilier auprès d'une banque.
          </p>
          <h3>La simulation PTZ avec Cogedim</h3>
          <p>
            Sachez que la simulation de votre prêt à taux zéro est totalement
            gratuite sur notre site Cogedim. La simulation ne vous prendra que
            quelques minutes et il est possible de la réaliser sur votre
            ordinateur ou votre smartphone. En quelques clics, le montant
            maximal de votre prêt à taux zéro sera affiché sur votre écran. Et
            si vous souhaitez de plus amples informations, un conseiller Cogedim
            est à votre disposition pour en discuter.
          </p>
          <h3>Le délai de remboursement du PTZ</h3>
          <p>
            La durée de remboursement du crédit immobilier sans intérêt est
            limitée à 25 ans. Par ailleurs, ce délai peut être raccourci à 5
            ans, à 10 ans, à 15 ans ou à 20 ans. Il dépend pratiquement de vos
            revenus. Votre capacité d'endettement s'avère également importante.
          </p>
          <p>Les banques considèrent aussi des éléments basiques comme :</p>
          <ul>
            <li>Le coût total de l'opération ;</li>
            <li>La localisation du futur logement ;</li>
            <li>La composition du foyer.</li>
          </ul>
          <p>
            Plus votre mensualité est élevée, plus la durée du prêt est courte.
            La banque étudie votre capacité de remboursement qui ne doit pas
            dépasser 33% des revenus de votre foyer. Selon votre cas, il est
            possible de dépasser ce plafond, surtout lorsqu’il s’agit d’un
            crédit de courte durée.
          </p>
        </div>
        <div class="block">
          <h2>Les démarches obligatoires pour bénéficier du PTZ</h2>
          <p>
            Avant de déposer votre demande de prêt à taux zéro, il convient de
            choisir un établissement bancaire ayant signé une convention avec
            l'État. Alors si vous disposez déjà d'un compte dans une banque de
            votre région, demandez à votre gestionnaire de compte si sa banque
            est éligible au dispositif. Si ce n'est pas le cas, un conseiller
            Cogedim pourra prendre contact avec vous pour en parler. Dans tous
            les cas, veillez à bien préparer la présentation de votre projet
            immobilier afin de garantir l'obtention de votre prêt à taux zéro
            (avec le coût total du projet immobilier).
          </p>
          <p>
            Ensuite, il faut savoir que pour bénéficier du PTZ il est essentiel
            de respecter certains critères pour obtenir le prêt et débloquer le
            financement dans les meilleurs délais. Pour cela, il est nécessaire
            de préparer à l'avance les pièces justificatives pour déposer une
            demande de prêt à taux zéro auprès d'un établissement bancaire.
          </p>
          <p>Pour cela, il faut préparer les documents suivants :</p>
          <ul>
            <li>
              une copie certifiée de votre pièce d'identité (carte nationale
              d'identité ou passeport),
            </li>
            <li>
              une attestation sur l'honneur qu'il s'agit de votre première
              acquisition
            </li>
            <li>et un contrat de réservation.</li>
          </ul>
          <p>
            Si vous habitez gratuitement chez un membre de votre famille, il
            faut présenter une attestation d'hébergement. Par contre, pour les
            locataires, un contrat de bail de location s'avère obligatoire.
          </p>
        </div>
        <div class="block">
          <h2>Comment assurer le remboursement du PTZ ?</h2>
          <p>
            Comme chaque prêt immobilier, le remboursement de votre prêt à taux
            zéro est obligatoire. La banque prélève une mensualité fixe sur
            votre compte bancaire pendant une durée déterminée comme négocié au
            cours du contrat initial.
          </p>
          <p>
            Ainsi, nous vous conseillons de choisir un taux de mensualité bas
            vous permettant de couvrir correctement vos charges courantes. Dans
            le cas où vous souhaitez régler votre dette dans les meilleurs
            délais, envisagez d'augmenter votre apport personnel. Ainsi, vous
            pouvez diminuer le capital nécessaire à votre emprunt.
          </p>
          <p>
            Enfin, pour le calcul de votre PTZ et le coût total de l'opération,
            n'oubliez pas de prévoir les frais de notaire pour l'achat de votre
            logement neuf.
          </p>
        </div>
        <div class="block">
          <h2>Autres informations à savoir sur le PTZ</h2>
          <p>
            Comme le PTZ fait partie des opportunités financées par l'Etat, ses
            conditions peuvent être variables. Suite à un décret publié au mois
            de novembre 2020, les conditions de ressources seront révisées à
            partir du 1er janvier 2022. Le revenu fiscal de référence de
            l'emprunteur devrait être pris en compte à la date d'émission de la
            proposition de crédit immobilier.
          </p>
        </div>
        <div class="block">
          <h2>Le prêt à taux Zéro est-il fait pour vous ?</h2>
          <p>
            Vous êtes tenté par le PTZ, mais vous hésitez encore ? Le prêt à
            taux zéro vous permet d'acheter un logement neuf sans apport
            personnel, puis de le rembourser sans intérêt au fil du temps. En
            effet, le PTZ est conçu non seulement pour vous encourager à devenir
            propriétaire de votre résidence principale, mais il peut aussi vous
            faire économiser de l'argent si vous le gérez de façon responsable.
            Cependant, si vous ne remboursez pas le prêt dans les délais
            convenus, le prêt à taux zéro peut représenter un coût élevé.
          </p>
          <p>
            Cela ne veut pas dire que vous ne devriez pas saisir l'occasion
            d'acquérir la PTZ. Sachez simplement dans quoi vous vous embarquez
            avant de vous décider. Pour éviter cela, Cogedim met à votre
            disposition son équipe pour vous accompagner dans vos démarches.
          </p>
        </div>
      </section>
    </>
  );
}
